/**
 * Sourced from https://github.com/lumecann/web-lumeolo/tree/main/src/components/svg/Logo
 */
import React from 'react';
import styles from './Logo.module.scss';

const Logo = ({ className, testId }) => (
  <svg
    data-testid={testId}
    className={[className, styles.svg].join(' ')}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 208.2 214.4"
    xmlSpace="preserve"
  >
    <g>
      <path
        className={styles['fill-orange']}
        d="M136,143.2c0,23.7-12.9,44.4-32,55.5c-19.2-11.1-32-31.8-32-55.5H136z"
      />
      <g>
        <path
          className={styles['fill-purple']}
          d="M136,143.7H71.9c0-23.7,12.9-44.4,32-55.5C123.1,99.3,136,120,136,143.7z"
        />
        <path
          className={styles['fill-purple']}
          d="M101.8,72.4H17.3C25.8,57.8,41.5,48,59.6,48S93.4,57.8,101.8,72.4z"
        />
        <path
          className={styles['fill-purple']}
          d="M97.6,81.9l-78.5,78.5c-5.7-21.4-0.2-45.1,16.6-61.9S76.2,76.2,97.6,81.9z"
        />
        <path
          className={styles['fill-purple']}
          d="M190.5,72.4h-84.5c8.4-14.6,24.2-24.4,42.2-24.4S182.1,57.8,190.5,72.4z"
        />
        <path
          className={styles['fill-purple']}
          d="M188.8,160.4l-78.5-78.5c21.4-5.7,45.1-0.2,61.9,16.6S194.5,139,188.8,160.4z"
        />
        <circle className={styles['fill-purple']} cx="103.9" cy="36.2" r="20" />
      </g>
    </g>
  </svg>
);

export default Logo;
