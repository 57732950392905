import { memo } from 'react';
import Button from 'react-bootstrap/Button';
import Link from 'components/Link';
import logger from 'config/logger';

/**
 * Formats a single line address and a phone number
 * @param {*} address e.g., 119 S Stephenson Ave, Iron Mountain, MI 49801
 * @param {*} phoneNumber e.g., 906.291.3335
 * @returns object
 */
const addressFormatter = (address, phoneNumber) => {
  try {
    const addressSegments = address?.split(',');
    const street = addressSegments?.[0] ?? '';
    const cityStateZip = addressSegments?.slice(1).toString();
    const phone = phoneNumber?.replace(/(\d{3}).(\d{3}).(\d{4})/, '($1) $2-$3');
    return { street, cityStateZip, phone };
  } catch (err) {
    logger.error(err);
    return { street: address, cityStateZip: '', phone: phoneNumber };
  }
};

const getStoreTitle = (title, selectLocation, url) => {
  const storeName = (
    <h3 className="font-acumin fs-10 fw-700 pb-8 m-0 text-start">{title}</h3>
  );
  if (selectLocation) {
    return (
      <Button
        className="p-0"
        data-value="store-title"
        variant="none"
        type="button"
        onClick={selectLocation}
      >
        {storeName}
      </Button>
    );
  }
  if (url) {
    return <Link href={url}>{storeName}</Link>;
  }
  return storeName;
};

export function Address({ store, selectLocation, url = '' }) {
  const formattedAddress = addressFormatter(
    store?.store_address,
    store?.store_support?.contact
  );
  return (
    <address className="fs-7 m-0">
      {store?.title ? getStoreTitle(store.title, selectLocation, url) : null}
      {store?.store_closed ? (
        <p className="text-alert fs-8 fw-700 m-0 mb-2">
          Store is currently closed
        </p>
      ) : null}
      <p className="m-0">{formattedAddress?.street}</p>
      <p className="m-0">{formattedAddress?.cityStateZip}</p>
      <p className="m-0 fw-700">
        <a
          aria-label={`Call store ${store?.title}`}
          href={`tel:${formattedAddress?.phone}`}
        >
          {formattedAddress?.phone}
        </a>
      </p>
    </address>
  );
}

export default memo(Address);
