import Address from 'components/Address';
import styles from './LocationGrid.module.scss';
import Logo from '../../../svg/Logo';

const DEFAULT_CURRENT_LOCATION_HEADER = 'Lume Stores';
const DEFAULT_COMING_SOON_LOCATION_HEADER = 'Coming Soon Near You!';

const sortByTitle = (a, b) => {
  const storeA = a.title?.toUpperCase() ?? '';
  const storeB = b.title?.toUpperCase() ?? '';
  if (storeA < storeB) {
    return -1;
  }
  if (storeA > storeB) {
    return 1;
  }
  // titles are equal
  return 0;
};

function LocationGrid({
  coming_soon_location_header: comingSoonLocationHeader,
  coming_soon_locations: comingSoonLocations,
  current_location_header: currentLocationHeader,
  current_locations: currentLocations,
}) {
  const currentLumeLocations = Array.isArray(currentLocations)
    ? currentLocations?.sort(sortByTitle)
    : [];
  const comingSoonLumeLocations = Array.isArray(comingSoonLocations)
    ? comingSoonLocations?.sort(sortByTitle)
    : [];
  return (
    <section className="w-100">
      <div className="px-16 pb-20 text-primary pt-6">
        {currentLumeLocations?.length > 0 && (
          <>
            <h1 className="fs-22 fs-xl-30 fw-700 pb-16 text-center">
              {currentLocationHeader ?? DEFAULT_CURRENT_LOCATION_HEADER}
            </h1>
            <ul className={styles.allStores}>
              {currentLumeLocations?.map((store, index) => (
                <li key={store?.uid ?? index} className={styles.store}>
                  <Logo className={styles.logo} />
                  <Address store={store} url={store?.url} />
                </li>
              ))}
            </ul>
          </>
        )}
        {comingSoonLumeLocations?.length > 0 && (
          <>
            <h2 className="fs-22 fs-xl-30 fw-700 py-16 pt-xl-30 text-center">
              {comingSoonLocationHeader ?? DEFAULT_COMING_SOON_LOCATION_HEADER}
            </h2>
            <ul className={styles.allStores}>
              {comingSoonLumeLocations?.map((store, index) => (
                <li key={store?.uid ?? index} className={styles.store}>
                  <Logo className={styles.logo} />
                  <Address store={store} />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </section>
  );
}

export default LocationGrid;
